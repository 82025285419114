@import "../../../app//style//colors.scss";

.username-enter-wrapper {
  text-align: center;
  display: flex;
  height: 100%;
  max-width: 480px;
  margin: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: $backgroundPurple;
}

.background-pic5 {
  background-image: url("../../../shared/assets/Instlogo.webp");
  background-position: center 15%;
  background-size: 35%;
  background-repeat: no-repeat;
}
.background-pic6 {
  background-image: url("../../../shared/assets/BG6.webp");
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
