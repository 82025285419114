@import "../../../../app/style//colors.scss";

.instagram-account-section {
  width: 100%;
  .instagram-account-block {
    background-color: $white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 40px 16px;
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;

    p {
      color: $black;
    }
    p + p {
      color: $darkGray;
    }
    .forgotten-block {
      color: $darkGray;
      margin-bottom: 15px;
      text-decoration: none;
      a {
        text-decoration: none;
        color: $purple;
      }
    }
    .copyright-block {
      padding-top: 20px;
      border-top: 1px solid $grayLight;
      color: $copyright;
    }
  }
}
