@import "../../../app/style/colors.scss";

.emoji-block {
  width: 100%;
  height: 136px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid $grayLight;
  img {
    width: 30px;
  }
  .center-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  .emoji-wrapper {
    margin-bottom: 18px;
    padding: 14px;
    border-radius: 100px;
    border: 1px solid $grayLight;
  }
}
