@import "../../../../app/style/colors.scss";

.footer-flex-wrapper {
  background-color: $black;
  padding-left: 10px;
  padding-right: 10px;
  a {
    color: $white;
  }
  p {
    margin-bottom: 16px;
    text-align: left;
    color: $grayLight;
  }

  .text-wrapper {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    opacity: 0.4;
    padding-bottom: 60px;
    position: relative;
  }

  .text-wrapper::after {
    position: absolute;
    width: 100%;
    content: "";
    background-color: $grayLight;
    height: 1px;
    left: 0;
    bottom: 30px;
    opacity: 0.4;
  }
}

.flex-question {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;

  img {
    width: 24px;
  }

  .emoji-wrapper {
    margin-bottom: 18px;
    padding: 14px;
    border-radius: 100px;
    border: 1px solid #ecefff33;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
}

.contact-background-wrapper {
  background-color: rgba(255, 255, 255, 0.103);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  color: $white;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;
  margin-bottom: 60px;

  .contact-information-wrapper {
    padding-top: 34px;
    padding-bottom: 34px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;

    .purple-text {
      color: $backgroundPurple;
    }
  }
}

.contact-background-wrapper::after {
  position: absolute;
  width: 100%;
  content: "";
  background-color: $grayLight;
  opacity: 0.2;
  height: 1px;
  left: 0;
  bottom: -30px;
}

.footer-nav {
  display: flex;
  gap: 30px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  color: $white;
  padding-bottom: 20px;
}
