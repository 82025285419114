@import "../../../../app//style//colors.scss";

.onboarding-navigation-section {
  width: 100%;
  .onboarding-navigation-block {
    background-color: $white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 40px 16px;
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    p {
      color: $black;
    }
    p + p {
      color: $darkGray;
    }
  }
  .onboarding-navigation-button {
    width: 100%;
    max-width: 343px;
  }
}
