@import "../../../../app//style/colors.scss";

.get-analysis-section {
  position: relative;
  width: 100%;
  z-index: 2;

  .get-analysis-block {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    padding: 30px 16px;
    color: $black;
    .get-analysis-img {
      margin-bottom: 14px;
    }

    .get-analysis-p {
      color: $darkGray;
      margin-bottom: 14px;
    }
    .get-analysis-defence {
      max-width: 100%;
      margin-top: 40px;
      background-color: #eef1ff;
      border: 1px solid #c8d4fd;
      border-radius: 10px;
      width: 343px;
      box-sizing: border-box;
      padding: 12px 29px;

      .defence-img-block {
        padding: 8px;
        background-color: $appBlue;
      }
      .get-analysis-defence123 {
        margin-top: 200px;
      }

      .defence-text {
        margin-top: 10px;
      }
    }
  }
}
