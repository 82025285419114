@import "../../../app/style/colors.scss";

.get-paid-account {
  text-align: center;

  height: 100%;
  max-width: 480px;
  margin: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  background-color: #8d6df3;

  .background-wrapper {
    position: absolute;
    text-align: center;
    display: flex;
    height: 100%;
    max-width: 480px;
    width: 100%;
    margin: auto;
    background-image: url("../../../shared/assets/BG2.webp");
    background-position:
      40% 10%,
      center top;
    background-size: 160%, contain;
    background-repeat: no-repeat, no-repeat;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    background-color: #8d6df3;
    z-index: 1;
  }
  .upper-window-background {
    position: fixed;
    z-index: 10;
    max-width: 480px;
    width: 100%;
    height: 100%;
    background-color: #494b55c2;
    top: 0;
    transition: 0.2s ease all;
    opacity: 0;
    visibility: hidden;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
  .is-active {
    visibility: visible;
    opacity: 1;
  }
}

.recurly-hosted-field-container{
  background-color: #8d6df3;
}
