@import "../../../../app/style/colors.scss";

.pay-window {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 10px;
  padding: 30px 20px;
  max-width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  .title16-regular-outfit {
    margin-bottom: 24px;
  }
  .default-input {
    margin-bottom: 54px;
    position: relative;
  }
  .error-message-upper {
    color: $pink;
    text-align: center;
    margin-bottom: 24px;
  }
  .default-input::after {
    position: absolute;
    width: 100%;
    content: "";
    background-color: $grayLight;
    opacity: 1;
    height: 1px;
    left: 0;
    bottom: -15px;
  }
  svg {
    margin-bottom: 30px;
  }
  .pay-window-block {
    width: 343px;
    max-width: 100%;
  }
  .loader-wrapper {
    height: 255px;
    position: relative;
    .loader {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  .gpay-card-info-container {
    min-width: 100% !important;
    padding-top: 8px;
    height: 56px;
    border-radius: 10px;
  }
  position: relative;
  .error-message {
    color: $pink;
    text-align: center;
    margin-bottom: 20px;
  }
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}
.apple-pay-button {
  width: 100%;
  height: 56px;
  border-radius: 10px;
  &[disabled] {
    background-color: $grayLight;
    cursor: not-allowed;
    border-radius: 10px;
  }
}
@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 10px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black;
  }
}


.google-pay-button-container {

  &[disabled] {
    background-color: $grayLight;
    cursor: not-allowed;
    border-radius: 10px;
    .gpay-card-info-container {
      background-color: $grayLight;
      cursor: not-allowed;
      pointer-events: none;
      outline: 1px solid $grayLight;

      .gpay-card-info-animation-container {
        background-color: $grayLight;
        cursor: not-allowed;
      }
    }
    .gpay-card-info-animated-progress-bar-container {
      background-color: $grayLight;
      cursor: not-allowed;
    }

    iframe {
      background-color: $grayLight;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.gpay-card-info-container {
  background-color: #000;
  color: #fff;

  &[disabled] {
    background-color: transparent; 
    color: #666;
    cursor: not-allowed;
  }
}