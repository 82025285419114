@import "../../../app/style/colors.scss";

.account-creating-wrapper {
  max-width: 480px;
  margin: 0px auto;
  text-align: center;
  background-color: $white;
  .error-message{
    max-width: 480px;
    width: 100%;
    // top: 50%;
    // transform: translate(-50%);
    // position: absolute;
    margin-top: auto;
    margin-bottom: auto;
  
  }
}
