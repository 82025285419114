@import "../../../../app/style/colors.scss";

.upper-wrapper {
  display: flex;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  color: $white;
  justify-content: flex-end;
  z-index: 2;
  position: relative;
  padding-top: 24px;
  padding-left: 10px;
  padding-right: 10px;
  .upper-big-text {
    font-size: 30px;
    font-family: "Outfit-SemiBold";
    font-weight: 600;
    text-align: center;

    margin-top: 18px;
  }
  .upper-little-text {
    font-size: 16px;
    font-family: "Outfit-Regular";
    font-weight: 400;
    line-height: 125%;
    text-align: center;
    margin-top: 10px;
  }
  .get-follow-button-block {
    width: 100%;
    max-width: 343px;
  }
}
