@import "../../../../app//style/colors.scss";

.create-acc-section {
  position: relative;
  width: 100%;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;

  .create-acc-block {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    padding: 30px 16px;
    color: $black;
    .create-acc-img {
      margin-bottom: 14px;
    }
  }
}
