@import "../../../app/style/colors.scss";

.progress-circle {
  width: 220px;
  height: 220px;
  position: relative;

  .icon-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-svg {
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
    position: absolute;
    top: 0;
    left: 0;
  }

  .circle-background {
    fill: none;
  }

  .circle-progress {
    fill: none;
    stroke: $appBlue;
    stroke-width: 3;
    stroke-linecap: round;
    transition: stroke-dashoffset 1s;
    transform: rotate(0.25turn);
    transform-origin: center;
  }
}
