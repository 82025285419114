$white: #ffffff;
$purple: #6b3cff;
$hoverPurple: #5631d0;
$black: #2b2942;
$backgroundPurple: #9a7aff;
$grayLight: #ecefff;
$darkGray: #5f5d7e;
$copyright: #b0aecc;
$cardBorders: #ecefff;
$lightGreen: #ceff95;
$BG: #f8f9ff;
$grayMiddle: #b0aecc;
$pink: #ea2a86;
$timerBackground: #8d6df4;

$appBlue: #315df4;
$appBG: linear-gradient(#ffffff, #e4c9ff);
$appSecondaryButton: #f0deff;
$appRed: #ef4f4f;
$appBlack: #0e0f0f;
$appGrayLight: #686d72;
$appSecondBlack: #2d2e2e;
$appCard: #f8f1ff;
$InsightsBG: linear-gradient(#315df4, #ea48da);
$appPurple: #ea48da;
$appTextColor: #54585c;
