@import "../../../../app/style/colors.scss";

.default-input {
  width: 100%;

  .default-input-text {
    margin-bottom: 5px;
    text-align: left;
    color: $black;
  }

  .default-input-item {
    max-width: 100%;
    width: 343px;
    height: 56px;
    background-color: $BG;
    border-radius: 10px;
    color: $black;
    position: relative;
    outline: none;
    border: 1px solid $cardBorders;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;

    &:focus {
      border: 1px solid $purple;
    }
    &.error {
      color: $pink;
      border: 1px solid $pink;
    }
    &.error:focus {
      border: 1px solid $pink;
    }
  }
}
.fixed-width {
  width: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
