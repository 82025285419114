@import "../../../app/style/colors.scss";

.terms-page {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
  // height: 100%;
  position: relative;

  .upper-gradient {
    z-index: 0;
    background: linear-gradient(#e4c9ff, #ffffff);
    width: 100%;
    height: 280px;
    position: absolute;
  }
}
