@import "../../../../app/style/colors.scss";

.register-payment-form-block {
  width: calc(100%);
  .form-element {
    margin-top: 24px;
    text-align: left;
    width: calc(100%);

    .text-element {
      margin-bottom: 14px;
      margin-left: 8px;
    }
    .form-wrapper {
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 10px;
      max-width: 350px; 
      border: solid 2px $grayLight;
      background-color: $white;
      .default-input {
        margin-top: 20px;
      }
      .default-input:last-child {
        margin-bottom: 20px;
      }
      .form-flex {
        display: flex;
        justify-content: space-between;
        .recurly-element-number {
          width: 70px !important;
        }
        .flex {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
  .null-padding{
    margin-top: 5px !important;
    margin-bottom: 10px;
  }
}
