@import "../../../../app/style/colors.scss";

.terms-container {
  padding: 12px;
  z-index: 1;
  position: relative;
  font-family: "Urbanist-Medium";
  color: $black;
  h1 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
    font-family: "Urbanist-Bold";
    text-align: center;
  }

  .head {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family: "Urbanist-Bold";
  }

  i {
    font-style: italic;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  ul {
    display: block;
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;

    li:before {
      content: "—";
      position: relative;
      left: -5px;
    }
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 30px;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
    margin-top: 15px;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }

  h3 {
    font-family: "Urbanist-SemiBold";
  }

  ol.secondary {
    padding-top: 10px;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .main-list {
    font-family: "Urbanist-Bold";
    font-size: 17px;
  }
}
