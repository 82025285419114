@import "../../../app/style/colors.scss";

.flex-timer {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 32px);
  max-width: 360px;
  margin: 30px 16px;
  .timer-element {
    background-color: $timerBackground;
    width: 94px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  .timer-element-wrapper {
    p {
      margin-top: 10px;
    }
  }
  .double-dots {
    line-height: 200%;
  }
}

@media screen and (max-width: 380px) {
  .flex-timer {
    margin: 30px 0px;
    .timer-element {
      width: 74px;
      height: 70px;
      font-size: 32px;
    }
    .double-dots {
      line-height: 150%;
    }
  }
}

@media screen and (max-width: 320px) {
  .flex-timer {
    .timer-element {
      width: 64px;
      height: 60px;
      font-size: 24px;
    }
    .double-dots {
      line-height: 120%;
    }
  }
}
