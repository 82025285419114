@import "../../../../app/style/colors.scss";

.under-wrapper {
  z-index: 2;
  position: relative;
  padding-top: 24px;
  background-color: $BG;
  margin-top: 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  .under-wrapper-flex {
    display: flex;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    padding: 0 21px;
  }
  .emoji-wrapper-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: calc(100% - 10px);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .offer-wrapper {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    border: solid 2px $grayLight;
    margin-top: 24px;
    background-color: $white;
    max-width: 390px;
    width: 100%;
    .offer-header {
      position: relative;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .title20-semibold-outfit {
        position: absolute;
        top: 20px;
        right: 10px;
        width: 100px;
        text-align: right;
        color: $lightGreen;
      }
      img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
      }
    }

    .first-line {
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 20px;
      position: relative;
      margin-top: 16px;
      .right {
        display: flex;
        gap: 10px;
        .purple {
          color: $purple;
          text-decoration: line-through;
        }
      }
    }
    .first-line::after {
      position: absolute;
      width: 100%;
      content: "";
      background-color: $grayLight;
      height: 1px;
      left: 0;
      bottom: -12px;
      opacity: 1;
    }
    .second-line {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .pay-wrapper {
    padding: 50px 21px;
    display: flex;
    flex-direction: column;
    .or-wrapper {
      margin-top: 24px;
      margin-bottom: 24px;
      position: relative;
      z-index: 10;
    }
    .default-button-item {
      width: 390px;
    }
    .apple-button-item {
      width: 390px;
    }
    .google-button-item {
      width: 390px;
    }
    .or-wrapper::after {
      position: absolute;
      width: 100%;
      max-width: 25px;
      content: "OR";
      background-color: $BG;
      opacity: 1;
      height: 10px;
      left: 50%;
      transform: translate(-50%);
      bottom: 2px;
      z-index: 1;
    }

    .or-wrapper::before {
      position: absolute;
      width: 95%;
      max-width: 380px;
      content: "";
      background-color: #494b55;
      opacity: 0.2;
      height: 1px;
      left: 50%;
      transform: translate(-50%);
      bottom: 7px;
    }
  }
  .error-message {
    color: $pink;
    margin-top: 16px;
  }
  .error-center{
    text-align: center;
  }
}
