@import "./style/colors.scss";

.Main-Wrapper {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  box-sizing: border-box;
}
html {
  background-color: $backgroundPurple;
}
a {
  text-decoration: none !important;
}

.flex-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  .news-aricle-page {
    flex: 1;
  }
  .news-page {
    flex: 1;
  }
  .account-information-page {
    flex: 1;
    width: 100%;
  }
  .analytics-wrapper {
    flex: 1;
    width: 100%;
  }
}

a {
  text-decoration: none;
  color: $black;
}
