@import "../../../app//style//colors.scss";

.onboarding-wrapper {
  display: flex;
  height: 100%;
  max-width: 480px;
  margin: auto;
  background-image: url("../../../shared/assets/Pic1.webp"),
    url("../../../shared/assets/BG1.webp");
  background-position:
    center 30%,
    center top;
  background-size: 90%, contain;
  background-repeat: no-repeat, no-repeat;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $backgroundPurple;
  text-align: center;
}
