@import "../../../app/style/colors.scss";
.progress-bar {
  width: 205px;
  height: 4px;
  background-color: #ffffff7b;
  border-radius: 25px;
  overflow: hidden;
  .progress-fill {
    height: 100%;
    background-color: $white;
    transition: width 0.5s ease;
  }
}
