.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 56px;
  background-color: #eef3fe;
  cursor: pointer;
}
