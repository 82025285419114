.header-menu-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  text-align: center;

  .exit-button-wrapper {
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease all;
  }
  .exit-button-wrapper.isActive {
    opacity: 1;
    visibility: visible;
  }
  button {
    margin-top: 20px;
  }
  .header-menu {
    background-color: #f8f1ff;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    max-width: 490px;
    width: 100%;
    top: 0;
    z-index: 11;
    transform: translateY(-600px);
    transition: 0.5s ease all;
    padding-top: 20px;
  }
  .header-menu.isActive {
    transform: translateY(0);
  }
}
